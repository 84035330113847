<template>
  <div class="ownerQuit">
    <!--        <van-nav-bar title="业主退租" left-arrow @click-left="leftReturn"/>-->
    <rxNavBar :title="detailTitle"></rxNavBar>
    <div class="applyTime">申请时间：&nbsp;{{ totalInfos.applicationTime }}</div>
    <div class="text">
      <div class="complaint_message">
        <div class="wait-approve">待审批</div>
        <div class="comlaint_site">
          <div class="site">{{ contractDetails.houseAddress }}</div>
        </div>
        <table class="houseInfo">
          <tr>
            <td>
              <div class="boldFont">编号：<span class="normalFont">{{ contractDetails.contractCode }}</span></div>
            </td>
            <td>
              <div class="boldFont">到期日：<span class="normalFont"
                                              style="color: #EB7B65;">{{ totalInfos.oldContractEndTime }}</span></div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont">{{ type == 2 ? '业主姓名' : '租客姓名' }}：<span
                  class="normalFont">{{ type == 2 ? ownerContractInfo.ownerName : renterContractInfo.renterName }}</span>
              </div>
            </td>
            <td>
              <div class="boldFont">付款方式：<span class="normalFont">{{ totalInfos.oldPayTypeName }}</span></div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont">租金：<span
                  class="normalFont">{{ type == 2 ? ownerContractInfo.rentMoney : renterContractInfo.rentMoney }}元</span>
              </div>
            </td>
            <td>
              <div class="boldFont">押金：<span
                  class="normalFont">{{ type == 2 ? ownerContractInfo.depositMoney : renterContractInfo.depositMoney }}元</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont">租后赔偿：<span
                  class="normalFont">{{ type == 2 ? ownerContractInfo.afterrentCompensation : detailInfo.afterrentCompensation }}元</span>
              </div>
            </td>
          </tr>
        </table>
        <div>
          <van-button plain style="margin:10px 20px 10px 0;position:absolute;right:10px" type="primary"
                      @click="gotoOldContractInfo">查看合同
          </van-button>
          <van-button plain style="margin:10px" type="primary" @click="checkDelivery">查看物业交割</van-button>
        </div>
      </div>


      <!--解除类型-->
      <div class="part-inputpart">
        <div id="paymentMethodPanel" class="part-inputpart-row" @click="selectRelieveType">
          <span :class="contractInfo.relieveType ? 'redDot' : 'grayDot'"></span>
          <span class="pOne1">解除类型</span>
          <span class="content-divide">|</span>
          <span :class="contractInfo.relieveType? 'part-inputpart-row-normaltext': ''"
                class="part-inputpart-row-graytext">{{
              contractInfo.relieveType.dictionaryTitle
            }}</span>
          <img :class="relieveTypeShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'"
               class="part-inputpart-row-right"
               src="../../../assets/images/triangle.png"/>
        </div>
      </div>
      <van-popup v-model="relieveTypeShow" position="bottom">
        <van-picker :columns="relieveTypeList" show-toolbar value-key="dictionaryTitle"
                    @cancel="relieveTypeShow = false" @confirm="selectRelieveTypeOption"/>
      </van-popup>
      <!--        解除日期-->
      <div class="changeType" @click="relieveShow = true">
        <div :class="relieveDate=='' ? 'grayDot' : 'redDot'"></div>
        <div class="pOne1">解除日期</div>
        <div class="ver">|</div>
        <span class="vanCell"> {{ relieveDate }}</span>
        <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="relieveShow = true"/>
      </div>
      <van-popup v-model="relieveShow" class="selectTime" position="bottom">
        <div class="date-dropDownList">
          <van-datetime-picker v-model="currentDate" :min-date="minDate" item-height="35px" title="选择年月日" type="date"
                               @cancel="relieveCancel" @confirm="relieveOnConfirm"/>
        </div>
      </van-popup>
      <!--        实际离房时间-->
      <div class="changeType" @click="realShow = true">
        <div :class="realDate=='' ? 'grayDot' : 'redDot'"></div>
        <div class="pOne">实际离房时间</div>
        <div class="ver">|</div>
        <span class="vanCell"> {{ realDate }}</span>
        <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="realShow = true"/>
      </div>

      <van-popup v-model="realShow" class="selectTime" position="bottom">
        <div class="date-dropDownList">
          <van-datetime-picker v-model="currentRealDate" :min-date="minDate" item-height="35px" title="选择年月日"
                               type="date" @cancel="realCancel" @confirm="realOnConfirm"/>
        </div>
      </van-popup>
      <!--  有责违约？无责违约 -->
      <div class="part-inputpart">
        <div class="part-inputpart-row">
          <div class="redDot"></div>
          <div>
            <span :class="contractInfo.conscientiousBreak? 'part-inputpart-row-redtext': 'part-inputpart-row-graytext'"
                  class="part-inputpart-isSupport part-inputpart-row-header"
                  @click="updateConscientious">有责违约</span>
            <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
            <span :class="!contractInfo.conscientiousBreak? 'part-inputpart-row-redtext': 'part-inputpart-row-graytext'"
                  class="part-inputpart-woman part-inputpart-row-header"
                  @click="updateNoConscientious">无责违约</span>
          </div>
        </div>
      </div>
      <!-- 违约方 -->
      <div class="part-inputpart">
        <div id="paymentMethodPanel" class="part-inputpart-row" @click="selectDefaultParty">
          <span :class="contractInfo.defaultParty ? 'content-have' : 'content-none'"></span>
          <span class="part-inputpart-row-header">违约方</span>
          <span class="content-divide">|</span>
          <span :class="contractInfo.defaultParty? 'part-inputpart-row-normaltext': ''"
                class="part-inputpart-row-graytext">{{
              contractInfo.defaultParty.label
            }}</span>
          <img :class="defaultPartyShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'"
               class="part-inputpart-row-right"
               src="../../../assets/images/triangle.png"/>
        </div>
      </div>
      <van-popup v-model="defaultPartyShow" position="bottom">
        <van-picker :columns="wrongSideList" show-toolbar value-key="label" @cancel="defaultPartyShow = false"
                    @confirm="selectDefaultPartyOption"/>
      </van-popup>
      <!-- 解除原因 -->
      <div class="part-inputpart">
        <div id="paymentMethodPanel" class="part-inputpart-row" @click="selectRelieveReason">
          <span class="content-have"></span>
          <!--<span :class="contractInfo.relieveReason.dictionaryTitle ? 'redDot' : 'grayDot'"></span>-->
          <span class="part-inputpart-row-header" style="position:relative;">解除原因</span>
          <span class="content-divide">|</span>
          <span :class="contractInfo.relieveReason.dictionaryTitle? 'part-inputpart-row-normaltext': ''"
                class="part-inputpart-row-graytext">{{
              contractInfo.relieveReason.dictionaryTitle
            }}</span>
          <img :class="isRelieveReasonShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'"
               class="part-inputpart-row-right"
               src="../../../assets/images/triangle.png"/>
        </div>
      </div>
      <van-popup v-model="isRelieveReasonShow" position="bottom">
        <van-picker :columns="terminateReasonList" show-toolbar value-key="dictionaryTitle"
                    @cancel="isRelieveReasonShow = false" @confirm="selectRelieveReasonOption"/>
      </van-popup>
      <!--  维修赔偿  -->
      <div class="part-inputpart">
        <div class="part-inputpart-row">
          <div class="content-have"></div>
          <span class="part-inputpart-row-header">维修赔偿</span>
          <span class="content-divide">|</span>
          <div class="nopadding">
            <van-field v-model="contractInfo.maintenanceCompensation" class="nopadding" placeholder="维修赔偿"
                       type="number" @input="changeMaintenanceCompensation"/>
          </div>

          <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
        </div>
      </div>
      <!--        补充说明-->
      <div class="changeCause">
        <div class="part-inputpart-row">
          <div class="content-have"></div>
          <div class="pTwo">补充说明</div>
        </div>
        <textarea v-model="contractInfo.remarks" class="textareaInput"></textarea>
      </div>

      <!--  退租保洁 -->
      <div class="part-inputpart">
        <div class="part-inputpart-row">
          <div class="redDot"></div>
          <div>
            <span :class="contractInfo.checkoutClean? 'part-inputpart-row-redtext': 'part-inputpart-row-graytext'"
                  class="part-inputpart-isSupport part-inputpart-row-header"
                  @click="updateCheckout">生成退租保洁</span>
            <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
            <span :class="!contractInfo.checkoutClean? 'part-inputpart-row-redtext': 'part-inputpart-row-graytext'"
                  class="part-inputpart-woman part-inputpart-row-header"
                  @click="updateNoCheckout">不生成退租保洁</span>
          </div>
        </div>
      </div>
      <div style="font-size:14px;margin-left: 4%;width: 92%;text-align: right;margin-top: 3px;color: #999999">
        注：当前房源截止日为：{{detailInfo.houseEndTime}}
        若租客合同解除日期与房源截止日相同，则不生成退租保洁
      </div>
      <!--退房详情-->
      <!--<div class="accept" >退房详情</div>
            <div class="complaint_message">
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">正常退租：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.isNormalReturn=='0'?'否':'是'}}</span></div></td>
                        <td><div class="boldFont">解除日期：<span class="normalFont">{{totalInfos.estimatedDepartureTime}}</span></div></td>
                    </tr>
                    <tr>
                        <td v-if="type==2"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='1'?'业主':'平台'}}</span></div></td>
                        <td v-if="type==1"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='1'?'租客':'平台'}}</span></div></td>
                        <td><div class="boldFont">有责：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.defaultingType=='0'?'无':'有'}}</span></div></td>
                    </tr>
                </table>
                <div class="relieve">
                    <tr>
                        <td><div class="boldFont2" >解除类型：<span class="normalFont">{{totalInfos.contractTerminationTypeVal}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont2" >解除原因：<span class="normalFont1">{{totalInfos.contractTerminationReasonVal?totalInfos.contractTerminationReasonVal:'暂无'}}</span></div></td>
                    </tr>
                </div>
            </div>-->
      <!--退款详情-->
      <div class="accept">退款详情</div>
      <div class="complaint_message">
        <table v-if="type==1" class="houseInfo">
          <tr>
            <td>
              <div class="boldFont1">剩余租金：<span
                  class="normalFont3">{{ contractInfo.remainRentMoney == undefined ? '暂无' : contractInfo.remainRentMoney + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">剩余押金：<span
                  class="normalFont3"> {{ contractInfo.surplusDepositMoney == undefined ? '暂无' : contractInfo.surplusDepositMoney + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">剩余物品押金：<span
                  class="normalFont3"> {{ contractInfo.surplusgoodsPrice == undefined ? '暂无' : contractInfo.surplusgoodsPrice + '元' }}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="boldFont1">租后赔偿：<span
                  class="normalFont3">{{ contractInfo.afterrentCompensation == undefined ? '暂无' : contractInfo.afterrentCompensation + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">维修赔偿：<span
                  class="normalFont3">{{ contractInfo.maintenanceCompensation == undefined ? '暂无' : contractInfo.maintenanceCompensation + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">应收违约金：<span
                  class="normalFont3"> {{ contractInfo.breakMoney == undefined ? '暂无' : contractInfo.breakMoney + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">实际违约金：<span
                  class="normalFont3"> {{ contractInfo.realBreakMoney == undefined ? '暂无' : contractInfo.realBreakMoney + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">剩余水电费：<span
                  class="normalFont3">{{ contractInfo.remainWaterMoney == undefined ? '暂无' : contractInfo.remainWaterMoney + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">预留水电费：<span
                  class="normalFont3">{{ contractInfo.reservedWater == undefined ? '暂无' : contractInfo.reservedWater + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">剩余水费：<span
                  class="normalFont3">{{ contractInfo.surplusWater == undefined ? '暂无' : contractInfo.surplusWater + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">服务费：<span
                  class="normalFont3">{{ contractInfo.serviceCharge == undefined ? '暂无' : contractInfo.serviceCharge + '元' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">应退总额：<span class="normalFont3"
                                                style="color:red;font-weight:bold">{{ contractInfo.returnSum == undefined ? 0 + '元' : contractInfo.returnSum + '元' }}</span>
              </div>
            </td>
          </tr>

          <!--<tr>
                        <td><div class="boldFont1">剩余水电：<span class="normalFont3" > {{historyInfo.restWaterElectric == undefined ? '暂无' : historyInfo.restWaterElectric+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">违约金：<span class="normalFont3" >{{historyInfo.falsify == undefined ? '暂无' : historyInfo.falsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">滞纳金：<span class="normalFont3" >{{historyInfo.lateFee == undefined ? '暂无' : historyInfo.lateFee+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">装修赔偿：<span class="normalFont3" >{{historyInfo.payRepair == undefined ? '暂无' : historyInfo.payRepair+'元'}}</span></div></td>
                    </tr>

                    <tr>
                        <td><div class="boldFont1">利息：<span class="normalFont3" >{{historyInfo.interest == undefined ? '暂无' : historyInfo.interest+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1" >租客是否补款<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'(无需补款)':'(需补款)'}}</span>：<span class="normalFont3" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'否':historyInfo.userPayedMoney == undefined ? '暂无' : historyInfo.userPayedMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">实际违约金：<span class="normalFont3" >{{historyInfo.realFalsify == undefined ? '暂无' : historyInfo.realFalsify+'元'}}</span></div></td>
                    </tr>-->

        </table>
        <table v-if="type==2" class="houseInfo">
          <tr>
            <td>
              <div class="boldFont1">业主退回房租：<span
                  class="normalFont3">{{ historyInfo.restRentMoney != undefined ? historyInfo.restRentMoney + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">支付业主房租：<span
                  class="normalFont3"> {{ historyInfo.surplusRent != undefined ? historyInfo.surplusRent + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">违约金：<span
                  class="normalFont3">{{ historyInfo.realFalsify != undefined ? historyInfo.realFalsify + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">装修赔偿：<span
                  class="normalFont3">{{ historyInfo.payRepair != undefined ? historyInfo.payRepair + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">公司退回总额：<span
                  class="normalFont3">{{ historyInfo.userTotalPay != undefined ? historyInfo.userTotalPay + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont1">业主退回总额：<span
                  class="normalFont3">{{ historyInfo.realBreakMoney != undefined ? historyInfo.realBreakMoney + '元' : '暂无' }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="boldFont-remarks">补充说明：<span class="normalFont-remarks">{{ historyInfo.remarks }}</span></div>
            </td>
          </tr>
        </table>
        <!--                <table  class="houseInfo">-->
        <!--                    <tr>-->
        <!--                        <td><div class="boldFont1" >退回总额<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'':'(需补款)'}}</span>：<span class="normalFont7" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?historyInfo.userTotalPay:historyInfo.UserPayedMoney}}元</span></div></td>-->
        <!--                    </tr>-->
        <!--                </table>-->
        <div>
          <!--<van-button class="look" plain type="primary" @click="openElectricContract">查看解除协议</van-button>-->
        </div>
      </div>
      <div v-if="historyType=='换房' || historyType=='转租'">
        <div class="accept">新房信息</div>
        <div class="complaint_message">
          <div class="comlaint_site">
            <div class="site">{{ totalInfos.newContractAddress }}</div>
          </div>
          <table class="houseInfo">
            <tr>
              <td>
                <div class="boldFont">合同编号：<span class="normalFont">{{ totalInfos.newContractCode }}</span></div>
              </td>
              <td>
                <div class="boldFont">付款方式：<span class="normalFont">{{ totalInfos.newPayTypeName }}</span></div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="boldFont">租客姓名：<span class="normalFont">{{ newMiddlecontract.renterName }}</span></div>
              </td>
              <td>
                <div class="boldFont">押金：<span class="normalFont">{{ newMiddlecontract.depositMoney }}元</span></div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="boldFont">租金：<span class="normalFont">{{ newMiddlecontract.rentMoney }}元</span></div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="boldFont-1">租期：<span class="normalFont"
                                                 style="color: #EB7B65;">{{ totalInfos.newContractBeginTime + ' 至 ' + totalInfos.newContractEndTime }}</span>
                </div>
              </td>
            </tr>
          </table>
          <div>
            <van-button plain type="primary" @click="gotoNewContractInfo">查看合同</van-button>
          </div>
        </div>
      </div>

    </div>

    <van-button :class="{'saveButton_Enable': relieveDate&&realDate}" :disabled=" !(relieveDate &&realDate)"
                class="saveButton_Disable" @click="save">保存
    </van-button>

    <loadingFlag v-show="loadingFlag"></loadingFlag>


  </div>
</template>

<script>
import {
  NavBar, Search, DropdownMenu, DropdownItem, Button, Toast, Picker, DatetimePicker, Field,
  Popup, Loading, Overlay,
} from 'vant';
import {
  approvalContractDetail,
  queryBaseData,
  chanageContractAgree,
  chanageContractRefuse,
  findElectronicContractUrl, getQueryBaseData, renterContractInfo, renterContractHistoryOperation
} from "../../../getData/getData";
import {checkAndroid, checkIOS, getStaffId, openInWebview, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import util from '../../contract/util2'
import loadingFlag from "../../afterRent/maintain/LoadingFlag.vue";

export default {
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    rxNavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    loadingFlag,
  },
  name: "ownerQuit",
  methods: {
    countEveryMoney() {
      this.countSurplusRent()     //计算剩余租金
      this.computeBreakMoney()     //计算违约金
      this.computeUtilities()       //计算预留水电费
      if (this.detailInfo.water && (util.dateAddDay(this.detailInfo.water, 0)).getTime() < new Date(this.relieveDate).getTime()) {
        this.countReceiveWater()   //计算剩余水费
      } else {
        this.countSurplusWater()   //计算剩余水费
      }
      // if(this.detailInfo.service&&(util.dateAddDay(this.detailInfo.service,0)).getTime()<new Date(this.relieveDate).getTime()){
      //     this.countReceiveCharge()    //计算服务费
      // }else{
      //     this.countCoverCharge()       //计算服务费
      // }
      this.serviceChargeCalculation()
      this.countOtherService()

      this.countReturnMoney()
      this.computeRealBreakMoney()
    },
    countReturnMoney() {
      console.log('jisuan')

      // if(!this.contractInfo.maintenanceCompensation||this.contractInfo.maintenanceCompensation=='0'){   //没有维修赔偿或者维修赔偿为0，不计算
      //       return
      //   }
      var temp = -1;
      if (this.contractInfo.defaultParty.value == '0') {
        temp = 1;
      }
      let contractInfo = this.contractInfo
      // 剩余租金+剩余水电+剩余押金+剩余物品押金+应收违约金+剩余水费+服务费+保洁费-租后赔偿-维修赔偿-预留水电费
      contractInfo.returnSum = Number(Number(contractInfo.remainRentMoney ? contractInfo.remainRentMoney : 0)
          + Number(contractInfo.remainWaterMoney ? (contractInfo.remainWaterMoney != '-' ? contractInfo.remainWaterMoney : 0) : 0)
          + Number(contractInfo.surplusDepositMoney ? contractInfo.surplusDepositMoney : 0)
          + Number(contractInfo.surplusgoodsPrice ? contractInfo.surplusgoodsPrice : 0)
          + Number(temp * Number(contractInfo.breakMoney ? contractInfo.breakMoney : 0))
          - Number(contractInfo.afterrentCompensation ? contractInfo.afterrentCompensation : 0)
          - Number(contractInfo.maintenanceCompensation ? contractInfo.maintenanceCompensation : 0)
          - Number(contractInfo.reservedWater ? contractInfo.reservedWater : 0)
          - Number(contractInfo.interest ? contractInfo.interest : 0)
          + Number(contractInfo.surplusWater ? contractInfo.surplusWater : 0)
          + Number(contractInfo.serviceCharge ? contractInfo.serviceCharge : 0)
          +Number(contractInfo.cleanMoney?contractInfo.cleanMoney:0)
      ).toFixed(2)
      if (!contractInfo.breakMoney && this.contractInfo.realBreakMoney) {
        this.contractInfo.returnSum = (parseFloat(this.contractInfo.returnSum) - parseFloat(this.contractInfo.realBreakMoney ? -temp * this.contractInfo.realBreakMoney : 0)).toFixed(2)
      }
      if (contractInfo.returnSum < 0 || contractInfo.returnSum == '0.00') {
        contractInfo.returnSum = 0
      }
      console.log('计算')

    },
    //计算剩余租金
    countSurplusRent() {
      let val = this.relieveDate
      if (val) {
        //减去合同多余的天数
        let detailInfo = this.detailInfo
        val = util.dateAddDay(val, 0)
        var days = util.dayMinusOther(val, this.renterMoneyDate);
        // 计算日租金
        var moneyPerDay = util.getDayRentMoney(this.detailInfo.rentMoney);
        // 在最后缴费的一期账单前退租
        if (days > 0) {
          // 剩余租金=收到的租金-已住的天数
          // 计算住了多少天
          var inDays = util.dayMinusOther(util.dateAddDay(detailInfo.billBeginTime, 0), new Date(val)) + 1;
          if (!this.detailInfo.billBeginTime) {
            inDays = 0
          }
          this.contractInfo.remainRentMoney = (detailInfo.lastPayMoney - Number(moneyPerDay * inDays)).toFixed(2);
          if (this.contractInfo.remainRentMoney < 0) {
            this.contractInfo.remainRentMoney = 0;
          }
        } else if (days < 0) {
          // 剩余租金可能为负数
          this.contractInfo.remainRentMoney = Number(moneyPerDay * days).toFixed(2) >= 0 ? Number(moneyPerDay * days).toFixed(2) : (-1) * Number(moneyPerDay * days).toFixed(2) * (-1);
        } else {
          this.contractInfo.remainRentMoney = 0;
        }
        if (this.contractInfo.remainRentMoney > this.detailInfo.collectionRentMoney) {
          this.contractInfo.remainRentMoney = this.detailInfo.collectionRentMoney
        }
        //正常退租，并且时间不大于合同截至时间，剩余租金>0 不退租金
        if ((this.contractInfo.relieveType.id == 108 || this.contractInfo.relieveType.id == 112) && this.contractInfo.remainRentMoney > 0 && val.getTime() <= new Date(this.detailInfo.contractEndTime).getTime()) {
          this.contractInfo.remainRentMoney = 0;
        }
        /*let subletDate = new Date(val.getFullYear(), val.getMonth(),val.getDate());
                    subletDate.setDate(subletDate.getDate() + 1);
                    let year = subletDate.getFullYear();
                    let month = subletDate.getMonth()+1;
                    let day = subletDate.getDate();
                    month = month < 10 ? '0' + month : month;
                    day = day < 10 ? '0' + day : day;
                    this.newTenantInfo.subletDate = year + "-" + month + "-" + day;
                    this.newTenantInfo.subletDate_DateType = subletDate;*/
        if (new Date(val) > new Date(this.utilities.billPayTime)) {  //如果先应付后解除
          this.overdueDays = Math.ceil((val.getTime() - new Date(this.utilities.billPayTime).getTime()) / (1000 * 3600 * 24))
        } else {
          this.overdueDays = ''
        }
      } else {
        this.contractInfo.remainRentMoney = 0;
      }
    },
    //计算违约金
    computeBreakMoney() {
      var contractInfo = this.contractInfo;
      var hasResp = this.contractInfo.conscientiousBreak ? 1 : 0;
      var count = 0;
      if (!hasResp) {
        contractInfo.breakMoney = 0;
      } else {
        let multiple = 2
        if (this.historyType == '换房' || this.historyType == '转租') {
          multiple = 1;
        }
        if (isNaN(this.detailInfo.rentMoney)) {
          this.detailInfo.rentMoney = 0;
        }
        count = multiple * this.detailInfo.rentMoney;
      }
      contractInfo.breakMoney = count;
      // console.log('step2==============>获取实际违约金',contractInfo.breakMoney)
    },
    // 计算实际违约金 剩余租金+剩余水电费+剩余押金-违约金（租客为-，平台为+）-滞纳金
    computeRealBreakMoney: function () {
      var hasResp = this.contractInfo.conscientiousBreak ? 1 : 0;
      if (this.contractInfo.defaultParty.label != '平台') {
        // console.log('step3===========>进入计算')
        this.contractInfo.realBreakMoney = Number(parseFloat(this.contractInfo.remainRentMoney ? this.contractInfo.remainRentMoney : 0)   //剩余租金
            + parseFloat(this.contractInfo.remainWaterMoney ? this.contractInfo.remainWaterMoney : 0)    //水电费
            + parseFloat(this.contractInfo.surplusDepositMoney ? this.contractInfo.surplusDepositMoney : 0)
            + parseFloat(this.contractInfo.surplusgoodsPrice ? this.contractInfo.surplusgoodsPrice : 0)
            - Number(this.contractInfo.afterrentCompensation ? this.contractInfo.afterrentCompensation : 0)
            - Number(this.contractInfo.maintenanceCompensation ? this.contractInfo.maintenanceCompensation : 0)
            - Number(this.contractInfo.reservedWater ? this.contractInfo.reservedWater : 0)
            - Number(this.contractInfo.interest ? this.contractInfo.interest : 0)
            + parseFloat(this.contractInfo.surplusWater ? this.contractInfo.surplusWater : 0)
            + parseFloat(this.contractInfo.serviceCharge ? this.contractInfo.serviceCharge : 0)   //服务费
        ).toFixed(2)
        // console.log('step3=========>last剩余水电费',this.contractInfo.remainWaterMoney)
        // console.log('step3=========>last预留水电费',this.contractInfo.reservedWater)
        // console.log('step3=================>计算时候的实际违约金',this.contractInfo.breakMoney)
        if (this.contractInfo.willPay) {
          this.contractInfo.realBreakMoney = Number(this.contractInfo.realBreakMoney) + Number(this.contractInfo.willPayCount);
        }
        if (parseFloat(this.contractInfo.realBreakMoney ? this.contractInfo.realBreakMoney : 0) >= parseFloat(this.contractInfo.breakMoney ? this.contractInfo.breakMoney : 0)) {
          this.contractInfo.returnSum = (parseFloat(this.contractInfo.realBreakMoney) - parseFloat(this.contractInfo.breakMoney ? this.contractInfo.breakMoney : 0)).toFixed(2)
          // console.log('应退总额',this.contractInfo.returnSum)
          this.contractInfo.realBreakMoney = parseFloat(this.contractInfo.breakMoney ? this.contractInfo.breakMoney : 0)
        } else {
          this.contractInfo.realBreakMoney = parseFloat(this.contractInfo.realBreakMoney ? this.contractInfo.realBreakMoney : 0)
        }
        if (this.historyType == '换房') {
          this.contractInfo.realBreakMoney = parseFloat(this.contractInfo.breakMoney ? this.contractInfo.breakMoney : 0)
        }
      } else {
        this.contractInfo.realBreakMoney = Number(parseFloat(this.contractInfo.remainRentMoney ? this.contractInfo.remainRentMoney : 0)
            + parseFloat(this.contractInfo.remainWaterMoney ? this.contractInfo.remainWaterMoney : 0)
            + parseFloat(this.contractInfo.surplusDepositMoney ? this.contractInfo.surplusDepositMoney : 0)
            + parseFloat(this.contractInfo.surplusgoodsPrice ? this.contractInfo.surplusgoodsPrice : 0)
            - Number(this.contractInfo.afterrentCompensation ? this.contractInfo.afterrentCompensation : 0)
            - Number(this.contractInfo.maintenanceCompensation ? this.contractInfo.maintenanceCompensation : 0)
            - Number(this.contractInfo.reservedWater ? this.contractInfo.reservedWater : 0)
            - Number(this.contractInfo.interest ? this.contractInfo.interest : 0)
            + parseFloat(this.contractInfo.surplusWater ? this.contractInfo.surplusWater : 0).toFixed(2)
            + parseFloat(this.contractInfo.serviceCharge ? this.contractInfo.serviceCharge : 0)).toFixed(2)

        if (this.contractInfo.willPay) {
          this.contractInfo.realBreakMoney = Number(this.contractInfo.realBreakMoney) + Number(this.contractInfo.willPayCount);
        }
        this.contractInfo.realBreakMoney = parseFloat(this.contractInfo.breakMoney ? this.contractInfo.breakMoney : 0)
      }
      //无责违约
      if (!hasResp) {
        this.contractInfo.realBreakMoney = 0
      }
    },
    //计算预留水电费
    computeUtilities() {
      // console.log(this.realDate)   //实际离房时间
      // console.log(this.detailInfo.contractBeginTime)   //合同开始时间
      // console.log('水电费计算函数====》',this.contractInfo.reservedWater)
      if (!this.realDate) {
        return
      }
      if (util.dateAddDay(this.detailInfo.contractBeginTime, 0).getTime() > util.dateAddDay(this.realDate, 0).getTime()) {
        this.contractInfo.reservedWater = 0
        return
      }
      let water = 0, electric = 0, rubbish = 0
      let realDate = new Date(this.realDate)
      rubbish = this.utilities.waterPowerGarbageList[0].garbageDisposal ? Number(this.utilities.waterPowerGarbageList[0].garbageDisposal) : 0
      let month = realDate.getMonth() >= 10 ? realDate.getMonth() : '0' + realDate.getMonth()
      let nextMonth = realDate.getMonth() + 1 >= 10 ? realDate.getMonth() + 1 : '0' + (realDate.getMonth() + 1)
      let time = realDate.getFullYear() + '-' + month + '-01'
      if (month == '00') {
        time = (realDate.getFullYear() - 1) + '-12-01'
      }
      if (Date.parse(time) < Date.parse(this.detailInfo.contractBeginTime)) {
        time = this.detailInfo.contractBeginTime
      }
      let nextTime = realDate.getFullYear() + '-' + nextMonth + '-01'
      if (realDate.getFullYear() == new Date(this.detailInfo.contractBeginTime).getFullYear() &&
          realDate.getMonth() == new Date(this.detailInfo.contractBeginTime).getMonth() &&
          1 < new Date(this.detailInfo.contractBeginTime).getDate()) {
        let day = new Date(this.detailInfo.contractBeginTime).getDate() >= 10 ? new Date(this.detailInfo.contractBeginTime).getDate() : '0' + new Date(this.detailInfo.contractBeginTime).getDate()
        nextTime = realDate.getFullYear() + '-' + nextMonth + '-' + day
      }
      //let day=new Date(realDate.getFullYear(),month,0).getDate()     //上个月有多少天
      if (util.dateAddDay(this.detailInfo.contractBeginTime, 0).getTime() > util.dateAddDay(realDate.getFullYear() + '-' + month + '-01', 0).getTime()) {
        time = this.detailInfo.contractBeginTime
        //day=0
      }
      let otherDay = util.getDaysSum(time, util.dateFormat(this.realDate))
      if ('1' == this.utilities.isintelligent) {
        electric = 0
      } else if (realDate.getDate() > Number(this.utilities.waterPowerGarbageList[0].timeNode)) {
        otherDay = util.getDaysSum(nextTime, util.dateFormat(this.realDate))
        electric = (Number(this.utilities.waterPowerGarbageList[0].power) * otherDay).toFixed(2)
      } else {
        electric = (Number(this.utilities.waterPowerGarbageList[0].power) * (otherDay)).toFixed(2)
      }
      if ('1' == this.utilities.isElectricityMeter) {
        water = 0
      } else if ('0' == this.utilities.isElectricityMeter) {
        water = (Number(this.utilities.waterPowerGarbageList[0].water) * (util.getDistanceMonths(this.detailInfo.contractBeginTime, util.dateFormat(this.realDate)))).toFixed(2)
      }
      this.contractInfo.reservedWater = Number(water) + Number(electric) + Number(rubbish) > 0 ? (Number(water) + Number(electric) + Number(rubbish)).toFixed(2) : 0
      console.log('step2=======>计算预留水电费的函数',this.contractInfo.reservedWater)

    },
    //计算剩余水费
    countSurplusWater() {
      if (!this.relieveDate) {
        return
      }
      if (this.contractInfo.totalWaterCharge == undefined || this.contractInfo.relieveType.id == 108 || this.contractInfo.relieveType.id == 112 || !this.contractInfo.waterObject) {
        this.contractInfo.surplusWater = 0
        return
      }
      this.betweenDays = this.getDaysBetween(util.dateAddDay(this.detailInfo.contractBeginTime, 0), util.dateAddDay(this.relieveDate, 0))
      let oldLeaveTime = this.detailInfo.contractBeginTime
      let waterRateCount = 0
      if ((util.dateAddDay(oldLeaveTime, 0)).getTime() == util.dateAddDay(this.relieveDate, 0).getTime()) {
        waterRateCount = 1
      } else {
        while (true) {
          if ((util.dateAddDay(oldLeaveTime, 0)).getTime() < util.dateAddDay(this.relieveDate, 0).getTime()) {
            waterRateCount++
            oldLeaveTime = util.dateAddDay(util.dateAddMonth(new Date(this.detailInfo.contractBeginTime), waterRateCount), -1)
          } else {
            break
          }
        }
      }
      this.contractInfo.surplusWater = Number((this.contractInfo.totalWaterCharge - waterRateCount * this.contractInfo.waterObject.amount).toFixed(2))
    },
    //计算应收水费
    countReceiveWater() {
      this.betweenDays = this.getDaysBetween(util.dateAddDay(this.detailInfo.water, 0), util.dateAddDay(this.relieveDate, 0))
      let oldLeaveTime = this.detailInfo.water
      let waterRateCount = 0
      if ((util.dateAddDay(oldLeaveTime, 1)).getTime() == new Date(this.relieveDate).getTime()) {
        waterRateCount = 1
      } else {
        while (true) {
          if ((util.dateAddDay(oldLeaveTime, 1)).getTime() < new Date(this.relieveDate).getTime()) {
            waterRateCount++
            oldLeaveTime = util.dateAddDay(util.dateAddMonth(new Date(this.detailInfo.water), waterRateCount), -1)
          } else {
            break
          }
        }
      }

      this.contractInfo.surplusWater = -waterRateCount * this.contractInfo.waterObject.amount
    },
    // 计算服务费
    serviceChargeCalculation() {
      //没有解除日期、没有房源类型、整租合租但没有配置服务费  费用为0
      if (!this.relieveDate || this.detailInfo.houseType != '集寓' && (!this.contractInfo.serviceChargeObject)) {
        this.contractInfo.serviceCharge = 0
        return
      }

      //相差天数
      if (util.dateAddDay(this.detailInfo.contractBeginTime, 0).getTime() > util.dateAddDay(this.relieveDate, 0).getTime()) {
        this.betweenDays = 0
      } else {
        this.betweenDays = this.getDaysBetween(util.dateAddDay(this.detailInfo.contractBeginTime, 0), util.dateAddDay(this.relieveDate, 0)) + 1
      }
      // 相差月份
      let waterRateCount = 0
      let oldLeaveTime = this.detailInfo.contractBeginTime
      if ((util.dateAddDay(oldLeaveTime, 0)).getTime() == new Date(this.relieveDate).getTime()) {
        waterRateCount = 1
      } else {
        while (true) {
          if ((util.dateAddDay(oldLeaveTime, 0)).getTime() < new Date(this.relieveDate).getTime()) {
            waterRateCount++
            oldLeaveTime = util.dateAddDay(util.dateAddMonth(new Date(this.detailInfo.contractBeginTime), waterRateCount), -1)
          } else {
            break
          }
        }
      }
      if (!this.contractInfo.serviceChargeObject.amount) {
        this.contractInfo.serviceChargeObject.amount = 0
      }
      console.log(this.betweenDays)  //相差天数
      console.log(waterRateCount)  //相差月份
      //单位为月
      if (this.contractInfo.serviceChargeObject.timeUnit == '月') {
        this.contractInfo.serviceCharge = this.serviceChangelist - waterRateCount * this.contractInfo.serviceChargeObject.amount
      } else {  //单位为天
        // 租客剩余金额
        let remainingAmount = this.serviceChangelist - this.betweenDays * this.contractInfo.serviceChargeObject.amount
        //（违约方为平台且有租客交款剩余）或（租客欠钱）多退少补
        if (this.contractInfo.defaultParty.value == 0 && remainingAmount > 0 || remainingAmount <= 0) {
          this.contractInfo.serviceCharge = remainingAmount
        } else {  //不退款
          this.contractInfo.serviceCharge = 0
        }
      }
    },
    countOtherService() {
      let month = Number(util.getDistanceMonths(this.detailInfo.contractBeginTime, this.relieveDate)) < 0 ? 0 : util.getDistanceMonths(this.detailInfo.contractBeginTime, this.relieveDate)
      let totalamount = this.detailInfo.monthFee && this.detailInfo.monthFee[0] ? this.detailInfo.monthFee[0].totalamount : 0
      this.contractInfo.serviceCharge = (Number(this.contractInfo.serviceCharge) + this.otherServiceFee - totalamount * month).toFixed(2)

    },
    changeMaintenanceCompensation() {
      this.computeRealBreakMoney()
      this.countReturnMoney()
    },
    //查询租客合同信息
    findRenterContractInfo(historyContractType) {
      let that = this
      let initData = {}
      initData.renterContractId = that.rentcontract_id
      initData.globaluser_id = getStaffId()
      initData.refund = '1'
      //initData.isNew = '1'
      renterContractInfo(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //应退金额
          that.contractInfo.returnSum = that.historyInfo.totalRefunded ? that.historyInfo.totalRefunded : that.contractInfo.returnSum
          console.log('详情')

          that.utilities = response.data.data
          that.detailInfo = response.data.data.data
          let detailInfo = that.detailInfo
          // if(!detailInfo.afterrentCompensation){
          //   detailInfo.afterrentCompensation=0
          // }
          if (detailInfo.serviceCharge != undefined) {
            that.contractInfo.serviceCharge = detailInfo.serviceCharge
          }
          //解除日期
          if (detailInfo.estimatedDepartureTime) {//退租日期
            that.relieveDate = util.dateFormat(detailInfo.estimatedDepartureTime);
          } else if (that.historyType == '转租' || that.historyType == '换房' || that.historyType == '退房') {
            that.relieveDate = util.dateFormat(new Date())
          } else {
            that.relieveDate = detailInfo.contractEndTime;
          }
          if (detailInfo.extendedLeaseEndTime && that.historyType == '续签') {
            that.relieveDate = util.dateFormat(detailInfo.extendedLeaseEndTime)
          }
          that.currentDate = new Date(that.relieveDate)
          //实际离房日期
          if (detailInfo.actualDepartureTime) {
            that.realDate = util.dateFormat(detailInfo.actualDepartureTime)
          }
          that.currentRealDate = new Date(that.realDate)
          //解除类型
          if (detailInfo.contractTerminationType) {
            that.contractInfo.relieveType.id = parseInt(detailInfo.contractTerminationType)
            that.contractInfo.relieveType.dictionaryTitle = detailInfo.contractTerminationTypeStr
          }

          //有责无责
          //that.decideDuty()
          //是否有责
          if (detailInfo.defaultingType) {
            that.contractInfo.conscientiousBreak = detailInfo.defaultingType == 1 ? true : false
            // console.log('step1==========>获取是否有责',that.contractInfo.conscientiousBreak)
          }
          //违约方
          if (detailInfo.defaultingParty) {
            that.contractInfo.defaultParty.value = parseInt(detailInfo.defaultingParty)
            that.contractInfo.defaultParty.label = detailInfo.defaultingPartyStr
          }
          //解除原因
          if (detailInfo.contractTerminationReason) {
            that.contractInfo.relieveReason.id = parseInt(detailInfo.contractTerminationReason)
            that.contractInfo.relieveReason.dictionaryTitle = detailInfo.contractTerminationReasonStr
          }
          //退租保洁
          if (detailInfo.refund == '0') {
            that.contractInfo.checkoutClean = false
          } else {
            that.contractInfo.checkoutClean = true
          }
          //补充说明
          that.contractInfo.remarks = detailInfo.remarks ? detailInfo.remarks : ''
          //剩余租金
          //that.contractInfo.remainRentMoney=that.historyInfo.restRentMoney?that.historyInfo.restRentMoney:0
          //实际违约金
          that.contractInfo.realBreakMoney = that.historyInfo.realFalsify ? that.historyInfo.realFalsify : 0
          //维修赔偿
          that.contractInfo.maintenanceCompensation = detailInfo.payRepair ? detailInfo.payRepair : 0
          //租后赔偿
          if (!detailInfo.afterrentCompensation) {
            that.contractInfo.afterrentCompensation = 0
          } else {
            that.contractInfo.afterrentCompensation = detailInfo.afterrentCompensation
          }
          //剩余水费
          that.contractInfo.surplusWater = that.historyInfo.restWater ? that.historyInfo.restWater : 0
          //服务费
          that.contractInfo.serviceCharge = that.historyInfo.serviceCharge ? that.historyInfo.serviceCharge : 0

          that.renterMoneyDate = new Date(detailInfo.lastDayPaid); //账单交至最后一个周期的截至时间，没有则为租客合同开始时间
          if (historyContractType != '1') {
            that.contractInfo.surplusDepositMoney = detailInfo.surplusRent ? detailInfo.surplusRent : 0; //剩余押金
          } else {
            that.contractInfo.surplusDepositMoney = 0
          }
          //剩余水电费
          that.contractInfo.remainWaterMoney = detailInfo.restWaterElectric ? (detailInfo.restWaterElectric >= 0 ? ((detailInfo.restWaterElectric)) : (((-1) * detailInfo.restWaterElectric) * (-1))) : 0; //剩余水电费

          if (detailInfo.refundInfo) {
            //that.contractInfo.remainRentMoney = detailInfo.refundInfo.surplusMoney?(detailInfo.refundInfo.surplusMoney>=0?(detailInfo.refundInfo.surplusMoney):(((-1)*detailInfo.refundInfo.surplusMoney)*(-1))):0; //剩余租金
            if (historyContractType != '1') {
              that.contractInfo.surplusDepositMoney = detailInfo.refundInfo.depositMoney ? detailInfo.refundInfo.depositMoney : 0; //剩余押金
            } else {
              that.contractInfo.surplusDepositMoney = 0
            }
            that.contractInfo.remainWaterMoney = that.detailInfo.remainWaterMoney ? (that.detailInfo.remainWaterMoney >= 0 ? ((that.detailInfo.remainWaterMoney)) : (((-1) * that.detailInfo.remainWaterMoney) * (-1))) : 0; //剩余水电费
          }
          //预留水电费
          that.contractInfo.reservedWater = that.historyInfo.reserveWaterElectric ? Number(that.historyInfo.reserveWaterElectric) : 0
          // console.log('接口里拿到的剩余水电费',that.contractInfo.remainWaterMoney)   //没问题
          // console.log('接口里拿到的预留水电费',that.historyInfo.reserveWaterElectric)   //没问题
          // console.log('初始化得实际预留水电费',that.contractInfo.reservedWater)   //没问题
          that.contractInfo.surplusgoodsPrice = Number(response.data.data.surplusgoodsPrice ? response.data.data.surplusgoodsPrice : 0)  //剩余物品押金
          that.contractInfo.interest = detailInfo.interest
          if (response.data.data.contractfee && response.data.data.contractfee.length > 0) {
            for (let i in response.data.data.contractfee) {
              if (response.data.data.contractfee[i].billFeeTypeVal == '服务费') {
                that.contractInfo.serviceChargeObject = response.data.data.contractfee[i]
              } else if (response.data.data.contractfee[i].billFeeTypeVal == '水费') {
                that.contractInfo.waterObject = response.data.data.contractfee[i]
              }
            }
          }
          if (response.data.data.serviceChangelist.length > 0) {
            for (let i in response.data.data.serviceChangelist) {
              that.serviceChangelist += response.data.data.serviceChangelist[i].actualAmount
            }
          }
          //实际已收水费
          if (response.data.data.totalWaterCharge > 0) {
            that.contractInfo.totalWaterCharge = response.data.data.totalWaterCharge
          }
          if (response.data.data.serviceChangeMonthlist.length > 0) {
            for (let i in response.data.data.serviceChangeMonthlist) {
              that.otherServiceFee += response.data.data.serviceChangeMonthlist[i].actualAmount
            }
          }
          that.$nextTick(()=>{
            if(detailInfo.reserveWaterElectric){
              that.contractInfo.reservedWater=detailInfo.reserveWaterElectric
              // console.log(2.0,that.contractInfo.reservedWater)
            }
            // console.log('详情',detailInfo.restWater)
            // if(detailInfo.restWater!=undefined){
            //   that.contractInfo.surplusWater=detailInfo.restWater
            // }
            // if(detailInfo.serviceCharge!=undefined){
            //   that.contractInfo.serviceCharge=detailInfo.serviceCharge
            // }
          })
          if (!detailInfo.reserveWaterElectric) {
            that.computeUtilities()       //计算预留水电费
          }
          if(!that.detailInfo.restWater){
            if (that.detailInfo.water && (util.dateAddDay(that.detailInfo.water, 0)).getTime() < new Date(that.relieveDate).getTime()) {
              that.countReceiveWater()   //计算剩余水费
            } else {
              that.countSurplusWater()   //计算剩余水费
            }
          }
          if(!that.detailInfo.serviceCharge){
            that.serviceChargeCalculation()  //计算服务费
            that.countOtherService()
          }

          that.countSurplusRent()     //计算剩余租金
          that.computeBreakMoney();     //计算违约金

          that.countReturnMoney()
          that.computeRealBreakMoney()
          // that.countEveryMoney()
        })
      })
    },
    //判断有责无责
    decideDuty() {
      if (this.contractInfo.relieveType.id == '108' || this.contractInfo.relieveType.id == '112') {  //正退/续签
        this.contractInfo.conscientiousBreak = false
        this.contractInfo.defaultParty.label = ''
        this.contractInfo.defaultParty.value = ''
      } else {
        this.contractInfo.conscientiousBreak = true
      }


    },
    getDaysBetween(dateString1, dateString2) {
      var startDate = Date.parse(dateString1);
      var endDate = Date.parse(dateString2);
      if (startDate > endDate) {
        return 0;
      }
      if (startDate == endDate) {
        return 0;
      }
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return days;
    },
    selectRelieveType() {
      this.relieveTypeShow = !this.relieveTypeShow
    },
    //解除类型
    selectRelieveTypeOption(item) {
      this.relieveTypeShow = false
      this.contractInfo.relieveType = item
      this.decideDuty()            //有责无责
      this.countEveryMoney()     //计算应退总额
    },
    relieveCancel() {
      this.relieveShow = false
    },
    relieveOnConfirm(value) {
      if (this.historyType != '续签' && value.getTime() > (new Date()).getTime() + 24 * 3600 * 1000 * 60) {  //不是续签
        responseUtil.alertMsg(this, '不可选择当前日期两个月后的日期')
        return
      }
      this.relieveShow = false
      var date = new Date(value)
      this.relieveDate = this.formatDate(date)
      this.countEveryMoney()

    },
    realCancel() {
      this.realShow = false
      this.showLeaseTime = false
    },
    realOnConfirm(value) {
      //实际离房时间在解除时间两个月前~解除时间之间
      if (value.getTime() > new Date(this.relieveDate).getTime() || value.getTime() < new Date(this.relieveDate).getTime() - 24 * 3600 * 1000 * 60) {
        responseUtil.alertMsg(this, '所选日期应为解除日期前两个月内~解除日期')
        return
      }
      this.realShow = false
      var date = new Date(value)
      this.realDate = this.formatDate(date)
      this.countEveryMoney()

    },
    //有责违约
    updateConscientious() {
      if (this.contractInfo.relieveType.id == 108 || this.contractInfo.relieveType.id == 112) {
        return
      }
      this.contractInfo.conscientiousBreak = true
      this.countEveryMoney()
    },
    //无责违约
    updateNoConscientious() {
      this.contractInfo.conscientiousBreak = false
      this.countEveryMoney()
    },
    //违约方
    selectDefaultPartyOption(item) {
      this.defaultPartyShow = false
      this.contractInfo.defaultParty = item
      this.countEveryMoney()
    },
    selectDefaultParty() {
      if (this.contractInfo.relieveType.dictionaryTitle == '正常退房') {  //正退不给改
        return
      }
      this.defaultPartyShow = !this.defaultPartyShow
    },
    //解除原因
    selectRelieveReason() {
      // if(this.editable){
      //     return
      // }
      if (this.contractInfo.relieveType.dictionaryTitle == '正常退房') {  //正退不给改
        return
      }
      this.isRelieveReasonShow = !this.isRelieveReasonShow
    },
    selectRelieveReasonOption(item) {
      this.isRelieveReasonShow = false
      this.contractInfo.relieveReason = item
    },
    //退租保洁
    updateCheckout() {
      this.contractInfo.checkoutClean = true
    },
    updateNoCheckout() {
      this.contractInfo.checkoutClean = false
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var days = date.getDate();
      if (days < 10) days = "0" + days;
      return year + "-" + month + "-" + days;
    },
    //基础数据
    getBaseData: function () {
      var me = this;
      getQueryBaseData({
        dbName: ['contractTerminationType', 'contractTerminationReason', 'subletReason'],
      }).then(function (data) {
        responseUtil.dealResponse(me, data, () => {
          var lists = data.data.data;
          me.relieveTypeList = lists['contractTerminationType'];
          // if(me.historyType=='退房'){
          //   me.relieveTypeList.splice(2,4)
          // }
          me.terminateReasonList = lists.contractTerminationReason
          /*if(me.$route.query.fromType == '1'){
                            me.selectType('转租')
                        }else if(me.$route.query.fromType == '2'){
                        me.selectType('续签')
                        }*/
        })
      });
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1)
    },
    //审批操作保存接口
    save() {
      if (this.historyType != '续签' && new Date(this.relieveDate).getTime() > (new Date()).getTime() + 24 * 3600 * 1000 * 60) {  //不是续签
        responseUtil.alertMsg(this, '解除日期不可选择当前日期两个月后的日期')
        return
      }
      if (new Date(this.realDate).getTime() > new Date(this.relieveDate).getTime() || new Date(this.realDate).getTime() < new Date(this.relieveDate).getTime() - 24 * 3600 * 1000 * 60) {
        responseUtil.alertMsg(this, '实际离房日期应为解除日期前两个月内~解除日期')
        return
      }
      // if(this.type==1 && this.detailInfo.afterrentCompensation>this.contractInfo.maintenanceCompensation){
      //   responseUtil.alertMsg(this,'维修赔偿需大于等于租后赔偿')
      //   return
      // }
      // if(this.type==2 && this.ownerContractInfo.afterrentCompensation>this.contractInfo.maintenanceCompensation){
      //   responseUtil.alertMsg(this,'维修赔偿需大于等于租后赔偿')
      //   return
      // }
      let that = this
      // 租约变更时，涉及到后台生成假账单的字段
      // 实际违约金  realFalsify
      // 服务费  serviceCharge
      // 维修赔偿  payRepair
      // 剩余水电费  remainWaterMoney
      // 剩余租金  remainRentMoney
      // 剩余物品押金  surplusgoodsPrice
      // 剩余水费  waterRate
      // 剩余押金  surplusDepositMoney
      let changeInfoParam = {
        rentercontract_id: this.detailInfo.renterContractId,
        normalType: this.contractInfo.relieveType.id == '108' || this.contractInfo.relieveType.id == '112' ? '1' : '0',
        leaveTime: this.relieveDate,
        hasResponsibility: this.contractInfo.conscientiousBreak ? '1' : '0',
        wrongSide: this.contractInfo.defaultParty.value,
        terminateType: this.contractInfo.relieveType.id,
        falsify: this.contractInfo.breakMoney + '',
        terminateReason: this.contractInfo.relieveReason.id ? this.contractInfo.relieveReason.id : '',
        afterrentCompensation: this.contractInfo.afterrentCompensation ? Number(this.contractInfo.afterrentCompensation) : 0,
        payRepair: this.contractInfo.maintenanceCompensation ? Number(this.contractInfo.maintenanceCompensation) : 0,
        reservedWater: Number(this.contractInfo.reservedWater),
        interest: this.contractInfo.interest,
        addStaff_id: getStaffId(),
        returnSum: this.contractInfo.returnSum ? Number(this.contractInfo.returnSum) : 0,
        surplusDepositMoney: this.contractInfo.surplusDepositMoney ? this.contractInfo.surplusDepositMoney : 0,
        willPay: this.contractInfo.willPay,
        willPayCount: this.contractInfo.willPayCount,
        subletReason_id: this.detailInfo.subletReason_id ? this.detailInfo.subletReason_id : '',
        realFalsify: this.contractInfo.realBreakMoney ? this.contractInfo.realBreakMoney : 0,
        remainWaterMoney: this.contractInfo.remainWaterMoney ? this.contractInfo.remainWaterMoney : 0,
        remainRentMoney: this.contractInfo.remainRentMoney ? Number(this.contractInfo.remainRentMoney) : 0,
        contractHistoryStatus: this.detailInfo.contractHistoryStatus,
        id: this.ch_id, // 合同id
        remarks: this.contractInfo.remarks, //补充说明
        roomDetailedAddress: this.detailInfo.houseAddress,
        refund: this.contractInfo.checkoutClean ? 1 : 0,
        actualDepartureTime: this.realDate,
        serviceCharge: this.contractInfo.serviceCharge ? Number(this.contractInfo.serviceCharge) : 0,
        waterRate: this.contractInfo.surplusWater ? this.contractInfo.surplusWater : 0,
        lastDayPay: this.detailInfo.lastDayPay,
        surplusgoodsPrice: this.contractInfo.surplusgoodsPrice ? this.contractInfo.surplusgoodsPrice : 0,
        overdueDays: this.overdueDays,
        //contenType:me.contractInfo.changeType
      };
      if (this.historyType == '退房') {
        changeInfoParam.changeType = 1;
      } else if (this.historyType == '转租') {
        /*if(me.isRenter == 'renterContract' && (me.newTenantInfo.mobile == undefined || me.newTenantInfo.mobile == '' || me.newTenantInfo.renterName == '') ){
                            me.$Message.warning('请选择新租客');
                            return;
                        }*/
        //变更类型(0 新签 1 续签 2 退房子 3 违约退房4 转租 5 换房)
        changeInfoParam.changeType = 3
      } else if (this.historyType == '换房') {
        /*if(me.isRenter == 'renterContract' && me.newTenantInfo.newRoomId == ''){
                            me.$Message.warning('请选择新地址');
                            return;
                        }*/
        //变更类型(0 新签 1 续签 2 退房子 3 违约退房4 转租 5 换房)
        changeInfoParam.changeType = 4;
      } else if (this.historyType == '续签') {
        changeInfoParam.isToSign = true
        changeInfoParam.changeType = 2;
      }
      changeInfoParam.staff_id = getStaffId()
      changeInfoParam.isSaveAfter = 1
      // console.log(changeInfoParam)
      this.loadingFlag = true;
      renterContractHistoryOperation(changeInfoParam).then(function (response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertMsg(that, '审批成功')
          that.$router.go(-1)
        })
      })

    },
    //业主或者租客解除合同信息初始化加载
    approvalContractDetail() {
      //先初始化字典信息
      //this.initReasonAndTypeData()
      let that = this
      let initData = {}
      initData.contractHistoryId = that.ch_id
      approvalContractDetail(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.totalInfos = response.data.data
          if (that.type == 2) {
            //业主合同信息
            that.ownerContractInfo = that.totalInfos.ownercontract
            that.ownerContractInfo.afterrentCompensation = that.totalInfos.afterrentCompensation ? that.totalInfos.afterrentCompensation : '0'
            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
            //根据type和contractHistoryHistoryType动态改变标题
            that.checkTitle(that.type, historyContractType)
          } else {
            //租客合同信息
            that.renterContractInfo = that.totalInfos.rentercontract
            //租客合同信息
            that.renterContractInfo = that.totalInfos.rentercontract
            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
            //根据type和contractHistoryHistoryType动态改变标题
            that.checkTitle(that.type, historyContractType)
            that.findRenterContractInfo(historyContractType)
          }
          that.historyInfo = that.totalInfos.contracthistory
          that.contractDetails = that.totalInfos.contract
          that.newMiddlecontract = that.totalInfos.newMiddlecontract
          that.newContract = that.totalInfos.newContract
          that.countEveryMoney()
        })
      })
    },
    //动态改变导航标题方法
    checkTitle(type, historyContractType) {
      let firstText = '';
      let secondText = '';
      if (type == 2) {
        firstText = '业主';
        this.condition = false
      } else {
        firstText = '租客';
        this.condition = true
      }
      if (historyContractType == 1) {
        secondText = '续签';
      } else if (historyContractType == 2 || historyContractType == 3) {
        secondText = '退房';
      } else if (historyContractType == 4) {
        secondText = '转租';
      } else if (historyContractType == 5) {
        secondText = '换房';
      }
      this.detailTitle = firstText + secondText + '申请';
    },
    //初始化原因和拒绝类型字典项
    initReasonAndTypeData() {
      let that = this
      let initData = {}
      initData.dbName = ["contractTerminationReason", "contractTerminationType"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.refuseTypeList = response.data.data.contractTerminationType
          that.refuseReasonList = response.data.data.contractTerminationReason
        })
      })
    },
    //跳转原合同详情页面
    gotoOldContractInfo() {
      this.$router.push({
        name: 'contractDetail',
        query: {
          contractId: this.contractId,
          type: this.type,
          ownerOrRenterContractId: this.type == 2 ? this.ownerContractInfo.id : this.renterContractInfo.id,
          historyType: this.historyType,
          contractTypeVal: this.getContractVal(this.contractStatus),
          contractStatus: this.contractStatus,
          mode: '1',
          contractHistoryId: this.ch_id || '',
        }
      });
    },
    //查看物业交割
    checkDelivery() {
      let houseType = ''
      if (this.detailInfo.houseType == '合租') {
        houseType = '1'
      }
      this.$router.push({
        name: 'propertyDelivery',
        query: {
          contract_id: this.contractId,
          //contractPropertyId: contractPropertyId,
          cpid: this.cpid,
          relievecp_id: this.relievecp_id,
          type: "confirmQuit",
          ch_id: this.$route.query.ch_id,  //租约变更id
          condition: this.condition,   //租客true,业主false
          houseType: houseType
        },
      })
    },
    //跳转新合同详情页面
    gotoNewContractInfo() {
      this.$router.push({
        name: 'contractDetail',
        query: {
          contractId: this.newContract.id,
          type: this.type,
          ownerOrRenterContractId: this.newMiddlecontract.id,
          historyType: this.historyType,
          contractTypeVal: this.getContractVal(this.newContract.contractStatus),
          contractStatus: this.newContract.contractStatus,
          mode: '1',
          contractHistoryId: ''
        }
      });
    },
    getContractVal(status) {
      if (status == 4) {
        return '已解除'
      } else if (status == 5) {
        return '待签字'
      } else if (status == 7) {
        return '已作废'
      } else {
        return '租期中'
      }
    },
    //查看电子合同
    openElectricContract() {
      let that = this
      if (that.contractDetails.electricRelieveContract_id) {
        let queryData = {};
        queryData.contractId = that.contractDetails.electricRelieveContract_id;
        findElectronicContractUrl(queryData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            if (openInWebview()) {
              if (checkAndroid()) {
                window.open(response.data.data.url);
              } else if (checkIOS()) {
                window.location.href = response.data.data.url;
              }
            }
          })
        })
      } else {
        responseUtil.alertMsg(that, '没有电子合同！');
      }
    },
    //租约变更为退房，续签，换房，转租
    leaseChange() {
      if (this.historyType == '退房') {
        //违约方
        this.contractInfo.defaultParty.value = 2
        this.contractInfo.defaultParty.label = '租客'
      } else if (this.historyType == '续签') {

      } else if (this.historyType == '转租') {
        this.contractInfo.conscientiousBreak = true
      } else if (this.historyType == '换房') {
        this.contractInfo.conscientiousBreak = true
      }
    },
  },
  created() {
    this.contractId = this.$route.query.contractId
    this.ch_id = this.$route.query.ch_id
    this.id = this.$route.query.id
    this.lastRefuseCount = this.$route.query.lastRefuseCount
    this.lastRefuseReason = this.$route.query.lastRefuseReason
    this.type = this.$route.query.type
    this.historyType = this.$route.query.historyType
    this.contractTypeVal = this.$route.query.contractTypeVal
    this.contractStatus = this.$route.query.contractStatus
    this.mode = this.$route.query.mode
    this.approvalFinishId = this.$route.query.approvalFinishId
    this.rentcontract_id = this.$route.query.rentcontract_id
    this.cpid = this.$route.query.cpid
    this.relievecp_id = this.$route.query.relievecp_id
  },
  mounted() {
    this.approvalContractDetail()
    this.getBaseData()
    //this.initReasonAndTypeData()
    //this.leaseChange()
  },
  data() {
    return {
      detailTitle: '',  //导航标题
      totalInfos: '',//总的信息
      ownerContractInfo: '',//业主合同信息
      renterContractInfo: '',//租客合同信息
      historyInfo: '',//合同历史信息
      contractDetails: '',//合同信息
      newContract: '',//新签的合同
      newMiddlecontract: '',//新签的(租客或者业主)合同
      number: -1,
      rejectReason: '',
      isReasonSelect: false,
      isPass: false,
      isReject: false,
      isSelect: '',
      isReasonShow: false,
      guidingPrice: "",
      //拒绝理由
      refuseReasonList: [],
      refuseTypeList: [],
      refuseType: '',
      refuseReason: '',
      checkRefuseReasonList: [],//审核拒绝原因
      applayApproveInfo: '',//申请详情
      approvalOpinion: '',//审批意见
      refuseReasonId: '',//拒绝理由的id
      dataList: {},
      contractId: '',
      ch_id: '',
      id: '',
      lastRefuseCount: '',
      lastRefuseReason: '',
      type: '',
      historyType: '',
      contractTypeVal: '',
      contractStatus: '',
      mode: '',
      approvalFinishId: '',
      rentcontract_id: '',
      condition: true,
      cpid: '',
      relievecp_id: '',
      contractInfo: {
        conscientiousBreak: false,
        relieveType: {},
        relieveReason: {},
        remarks: '',
        checkoutClean: false,
        remainRentMoney: 0,
        remainWaterMoney: 0,
        surplusDepositMoney: 0,
        surplusgoodsPrice: 0,
        breakMoney: 0,
        afterrentCompensation: '0',
        maintenanceCompensation: '0',
        reservedWater: 0,
        interest: 0,
        surplusWater: 0,
        serviceCharge: 0,
        serviceChargeObject: {},
        waterObject: '',
        defaultParty: {label: '', value: ''},    //违约方
        willPay: false,
        willPayCount: 0,
        realBreakMoney: 0,
        totalWaterCharge: 0
      },
      relieveTypeShow: false,
      relieveTypeList: [],
      relieveShow: false,
      relieveDate: '',
      currentDate: '',
      minDate: new Date('2022-01-01'), //  离房时间最小时间
      realDate: '',
      realShow: false,
      currentRealDate: '',
      isRelieveReasonShow: false,
      terminateReasonList: [],
      utilities: {},
      detailInfo: {},
      defaultPartyShow: false,
      wrongSideList: [
        {
          value: '2',
          label: '租客'
        },
        {
          value: '0',
          label: '平台'
        }
      ],
      renterMoneyDate: '',
      serviceChangelist: 0,
      overdueDays: '',
      otherServiceFee: 0,    //其他服务费
      loadingFlag: false,

    }
  }
}
</script>

<style scoped>
.ownerQuit {
  overflow: hidden;
  width: 100%;
}

.relieve {
  padding: 20px 0px 45px 15px;
}

.reject {
  padding-left: 30px;
  padding-top: 6px;
  width: 315px;
  height: 34px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.look {
  width: 120px;
  margin-left: 210px;
}

.wait-approve {
  width: 46px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
  background: linear-gradient(to right, #ffb96f, #ff6d44);
}

.applyTime {
  font-size: 12px;
  text-align: right;
  color: #999999;
  padding-right: 15px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.site {

}

.comlaint_site {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 5px 0px 10px 15px;
  font-size: 17px;
  border-bottom: 1px solid #EFEFEF;
  font-weight: bold;
}

.complaint_message {
  width: 92%;
  margin-left: 15px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.text {
  /* margin-left: 15px; */
}

.accept {
  height: 17px;
  color: rgba(255, 93, 59, 1);
  font-size: 12px;
  margin-top: 30px;
  margin-left: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: PingFangSC-Semibold;
}

.houseInfo {
  width: 345px;
  padding: 15px 10px 15px 10px;
  border-bottom: 1px solid #EFEFEF;
}

.boldFont {
  font-size: 14px;
  font-weight: bold;
  width: 157.5px;
  height: 24px;
}

.boldFont-1 {
  font-size: 14px;
  font-weight: bold;
  height: 24px;
}

.boldFont1 {
  font-size: 14px;
  font-weight: bold;
  width: 311px;
  height: 24px;
}

.boldFont2 {
  font-size: 14px;
  font-weight: bold;
  width: 315px;
  /*height: 24px;*/
}

.normalFont {
  font-size: 13px;
  font-weight: normal;
  color: #999;
}

.normalFont1 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  line-height: 25px;
}

.normalFont8 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 190px;

}

.normalFont2 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 186px;
}

.normalFont3 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  float: right;
}

.boldFont-remarks {
  font-size: 14px;
  font-weight: bold;
  width: 309px;
  /*height: 24px;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.normalFont-remarks {
  font-size: 13px;
  font-weight: normal;
  color: #999;
}

.normalFont4 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 190px;
}

.normalFont5 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 204px;
}

.normalFont6 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 218px;
}

.normalFont7 {
  font-size: 13px;
  font-weight: normal;
  color: #999;
  padding-left: 125px;
}


.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}


.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -50px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 15px;
  width: 50px;
  margin-right: 45px;

}


.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 15px;
}


.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  /*margin: 14px auto;*/
}

.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}


.suggestTitle {
  margin-left: 14px;
  margin-top: 12px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.textArea {
  resize: none;
  padding: 10px;
  margin: 39px auto 20px;
  height: 100px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: #FAFAFA;

}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}

.inputPrice {
  color: #ff3c00;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  font-weight: bold;
  border: none;
}

.passText {
  flex: 1;
  padding-right: 7px;
}

.rejectText {
  padding-left: 7px;
  padding-right: 33px;

}

.rightText {
  margin-left: auto;
  font-size: 15px;
  color: #999999;
}

.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}

.wait-approve {
  width: 46px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
  background: linear-gradient(to right, #ffb96f, #ff6d44);
}

.orangeColor {
  color: #ff3c00;
}

.choosedStyle {
  color: #ff3c00;
  font-weight: bold;
}

.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}

.descTitle {
  height: 25px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 20px;
}

.desc-Text {
  height: auto;
  padding-left: 14px;
  padding-bottom: 17px;
  padding-right: 20px;
}

.applyTime {
  font-size: 12px;
  text-align: right;
  color: #999999;
  padding-right: 15px;
  margin-top: 16px;
  margin-bottom: 6px;
}


.saveButton_Disable {

  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 30px 0px 40px 15px;
  font-size: 18px;
  color: white;
  line-height: 45px;
  text-align: center;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}

.displayBlock {
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  height: auto;
  margin: 15px auto;
}

.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  line-height: 1rem;
  height: 50px;
  margin: 5px auto 14px;
}


</style>
<style lang="less" scoped>
.changeType {
  width: 92%;
  height: 50px;
  line-height: 40px;
  display: flex;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin: 15px auto 0;
  align-items: center;

  .changeTypeImg {
    width: 25px;
    height: 25px;
  }

  .vanCell {
    /*height: 40px;*/
    /*line-height: 40px;*/
    width: 40%;
    border: none;
    font-size: 15px;
    color: #FF5D3B;
  }

  .vanCell-1 {
    /*height: 40px;*/
    /*line-height: 40px;*/
    width: 47%;
    border: none;
    font-size: 15px;
    color: #FF5D3B;
  }

  select {
    width: 215px;
    height: 40px;
    border: none;
    background-color: #FFFFFF;
    margin-bottom: 5px;
  }
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  margin-left: 15px;
  width: 92%;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.part-inputpart-row-line {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 65px;
  background-color: #ffffff;
  margin-top: 15px;
  flex-wrap: wrap;
}

.part-inputpart-row-short {
  width: 48%;
  float: left;
}

.part-inputpart-row-short-right {
  float: right;
}

.part-inputpart-row-short-graytext {
  color: #d8d8d8;
}

.part-inputpart-row-header {
  font-weight: 900;
}

.part-inputpart-row-sex {
  margin-top: 26px;
}

.part-inputpart-row-startPeriod {
  width: 60px;
  line-height: 21px;
  float: left;
}

.part-inputpart-row-endPeriod {
  width: 100px;
  height: 20px;
  line-height: 21px;
  margin-left: 8px;
  float: left;
}

.part-inputpart-row-graytext {
  width: 100px;
  color: #d8d8d8;
  flex: auto;
}

.part-inputpart-row-normaltext {
  color: black;
  flex: auto;
}

.part-inputpart-row-redtext {
  flex: auto;
  color: #ff5d3b;
}

.part-inputpart-isSupport {
  margin-left: 10px;
  margin-right: 30px;
}

.part-inputpart-woman {
  margin-left: 10px;

}

.part-inputpart-row-right {
  float: right;
  margin-right: 50px;
}

.part-inputpart-row-right-upArrow {
  width: 8px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;

}

.grayDot {
  border: 3px solid #999999;
  border-radius: 50%;
  margin-left: 15px;
}

.redDot {
  border: 3px solid #FF5D3B;
  border-radius: 50%;
  margin-left: 15px;
}

.ver {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
  color: #999999;
}

.pOne {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.pTwo {
  font-size: 15px;
  font-weight: bold;
  // margin-left: 10px;
}

.changeCause {
  width: 92%;
  height: 185px;
  margin: 27px auto;
  background-color: #FFFFFF;
  border-radius: 8px;

  .textareaTitle {
    display: flex;
    height: 45px;
    line-height: 45px;
    align-items: center;
  }

  .textareaInput {
    display: block;
    width: 84%;
    margin: -3px auto 20px;
    height: 90px;
    border-radius: 8px;
    background-color: #f7f7f7;
    border: none;
    resize: none;
    padding: 15px;
    font-size: 14px;
  }
}

.pOne1 {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 30px;
}
</style>
